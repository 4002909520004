import { createIcons, icons } from "lucide";

export default function triggerIcons() {
    (function () {
        "use strict";
        // Lucide Icons
        createIcons({
            icons,
            "stroke-width": 1.5,
            nameAttr: "data-lucide",
        });
        window.lucide = {
            createIcons: createIcons,
            icons: icons,
        };
    })();
};