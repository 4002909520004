import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropdowns"
export default class extends Controller {
  static targets = ["hide"]
  
  connect() {
    if (this.hasHideTarget) {
      if (document.querySelector(this.hideTarget.dataset['id'])) {
        const myDropdown = tailwind.Dropdown.getOrCreateInstance(document.querySelector(this.hideTarget.dataset['id']));
        myDropdown.toggle();
        this.hideTarget.remove();
      }
    };
  }
}
