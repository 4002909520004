import { Controller } from "@hotwired/stimulus"
// import triggerTomSelect from '../templatejs/tom-select'
import TomSelect from "tom-select";

// Connects to data-controller="tom-select"
export default class extends Controller {

  static values = {
    imageselect: {type: Boolean, default: false},
    imageurl: {type: String, default: ""}
  }

  connect() {
    if (this.element.classList.contains('tomselected')) return;
    let url = this.imageurlValue;
    const dropdownId = this.element.id;
    if (this.imageselectValue){
      new TomSelect(this.element,{
        plugins: {
          remove_button:{
            title:'Remove this item',
          }
        },
        valueField: 'id',
        labelField: 'name',
        searchField: ['name','description'],
        // fetch remote data
        load: function(query, callback) {
          var self = this;
          if( self.loading > 1 ){
            callback();
            return;
          }
          let queryUrl = url + "?q=" + encodeURIComponent(query);
          fetch(queryUrl)
            .then(response => response.json())
            .then(json => {
              console.log(json);
              callback(json);
            }).catch(()=>{
              callback();
            });

        },
        onLoad(){
          let dropdown = document.querySelector(`#${dropdownId}-ts-dropdown`)
          dropdown.classList.add("flex")
          dropdown.classList.add("flex-wrap")
          // dropdown.classList.add("grid")
          // dropdown.classList.add("grid-cols-12")
          // dropdown.classList.add("gap-2")
        },
        // custom rendering function for options
        render: {
          option: function(item, escape) {
            return `<div class="w-40 "><div class="grid grid-cols-12 gap-2">
                      <div class="col-span-12 grid place-content-center">
                        <img class="" src="${item.image_url}" />
                      </div>
                      <div class="col-span-12 grid place-content-center">
                        <div class="">
                          ${escape(item.name)}
                        </div>
                        <small>${escape(item.description)}</small>
                      </div>
                    </div></div>`;
          },
          item: function(item, escape) {
            return `<div class="w-40 "><div class="grid grid-cols-12 gap-2">
            <div class="col-span-12 grid place-content-center">
              <img class="" src="${item.image_url}" />
            </div>
            <div class="col-span-12 grid place-content-center">
              <small>${escape(item.name)}</small>
              <small>${escape(item.description)}</small>
            </div>
          </div></div>`;
          },
        },
      });
    }
  }
}
