import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

// document.addEventListener("turbo:frame-missing", (event) => {
//     console.log(event)
//     const { detail: { response, visit } } = event;
//     event.preventDefault();
//     console.log(response.url)
//     location.reload();
//     });


// document.addEventListener("turbo:before-fetch-request", async (event) => {
//     event.preventDefault()
//     // console.log(event)
//     // console.log(event.target)

//     // const elem = document.querySelector('#loading').cloneNode(true)
//     // elem.classList.remove("hidden")
//     // event.target.innerHTML = elem.innerHTML


//     event.detail.resume()
//   })


export { application }
