import { Controller } from "@hotwired/stimulus"
import "box-image-picker"

// Connects to data-controller="image-picker"
export default class extends Controller {
  connect() {
    console.log("Image Picker!")
    const imagepicker = new BoxImagePicker("#imagepicker", {
    });
  }
}
