import { Controller } from "@hotwired/stimulus"
// import { get } from '@rails/request.js'

// Connects to data-controller="Market"
export default class extends Controller {
  connect() {
  }

  changeMarket(event){
    document.querySelector('#walkthrough').src = '/walkthroughs/'+ event.target.value
  }

  addToProposal(event){
    event.target.disabled = true
    event.target.closest('form').requestSubmit()
  }

}
