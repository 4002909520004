import { Controller } from "@hotwired/stimulus"
import dropdown from "../tw-starter/dropdown";
import modal from "../tw-starter/modal";
import tab from "../tw-starter/tab";
import sideMenu from "../templatejs/side-menu"
import triggerIcons from '../templatejs/lucide'
import mobileMenu from '../templatejs/mobile-menu'
import tippy from 'tippy.js';
import { get } from '@rails/request.js'
import "@left4code/tw-starter/dist/js/svg-loader";
import "@left4code/tw-starter/dist/js/accordion";
import "@left4code/tw-starter/dist/js/alert";
import Toastify from "toastify-js";
import triggerTomSelect from '../templatejs/tom-select'
import "zoom-vanilla.js/dist/zoom-vanilla.min.js";
// import "@left4code/tw-starter/dist/js/modal";
// import "@left4code/tw-starter/dist/js/tab";



// Connects to data-controller="side_navigation"
export default class extends Controller {

  static targets = ["notification", "refreshContent", "refreshPage", "toggleClass", "tippy"]

  connect() {
    sideMenu();
    // Create dropdown triggers
    dropdown();
    // Creating Icons
    triggerIcons();
    // Initialize the mobile menu
    mobileMenu();
    // Create modal triggers
    modal();
    // Create tab triggers
    tab();
  }

  refreshPageTargetConnected(element) {
    element.remove()
    location.reload()
  }

  refreshContentTargetConnected(element) {
    triggerIcons();
    triggerTomSelect(document);
    element.remove()
  }

  toggleClassTargetConnected(element) {
    const target = element.dataset['target']
    const others = element.dataset['others']
    const className = element.dataset['class']

    if(className){
      if(others){
        document.querySelectorAll(others).forEach(el => {
          el.classList.remove(className);
        });
      }

      if(target){
        const item = document.querySelector(target)
        if(item){
          item.classList.add(className);
        }
      }

    }

    element.remove()
  }

  notificationTargetConnected(element){
    const elem = element.cloneNode(true)
    elem.classList.remove("hidden")
    delete elem.dataset.controller
    Toastify({
      node: elem,
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: "bottom",
      position: "right",
      stopOnFocus: true,
  }).showToast();
  element.remove()
  }



  hiddenToggle(event){
    const target = event.target.dataset.hiddenToggleTarget
    let chevron = null
    if(event.target.dataset.chevron){
      chevron = event.target
    }else{
      chevron = event.target.querySelector('[data-chevron]')
    }
    if(target){
      const elements = document.querySelectorAll(target)
      const tr = event.target.closest("tr")

      if(tr){
        tr.classList.toggle("bg-slate-100")
      }

      if(elements){
        if(chevron){
          chevron.style.transition = "all 0.2s ease-in-out";
          chevron.classList.toggle("rotate-180")
          chevron.classList.toggle("text-slate-300")
        }
        elements.forEach(element => {
          element.classList.toggle("hidden")
        });
      }
    }
  }

  // highlightToggle(event){
  //   const target = event.target.dataset.hiddenToggleTarget
  //   if(target){
  //     const elements = document.querySelectorAll(target)
  //     if(elements){
  //       event.target.classList.toggle("bg-yellow-100")
  //       elements.forEach(element => {
  //         element.classList.toggle("bg-yellow-100")
  //       });
  //     }
  //   }
  // }


  tippyTargetConnected(element){
    tippy(element, {
      content: element.dataset.title,
    });
    triggerIcons();
  }

  openDropdown(event){
    const myDropdown = tailwind.Dropdown.getOrCreateInstance(event.target.parentElement);
    myDropdown.toggle();
  }

  close(event){
    event.target.parentElement.remove()
  }

  changeSessionValue(event){
    get("/tools/change_session_value", {responseKind: "turbo-stream", query: {key: event.target.dataset.attribute, value: event.target.value, name: event.target.options[event.target.selectedIndex].text}})
  }

}
