import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

// Connects to data-controller="select"
export default class extends Controller {
  static targets = ["inventoryItemSelect", "standardSelect"]

  connect() {
  }

  standardSelectTargetConnected(element){
    new TomSelect(element,{
      valueField: 'id',
      labelField: 'name',
      maxOptions: null,
      searchField: ['name','id'],
      sortField:[{field:'name'}],
    });
  }

  inventoryItemSelectTargetConnected(element){
    new TomSelect(element,{
      valueField: 'id',
      labelField: 'name',
      optgroupField: 'class',
      maxOptions: null,
      searchField: ['name','display','id'],
      sortField:[{field:'name'}],
      render: {
        option: function(data, escape) {
          if(data.display){
            return JSON.parse(data.display);
          }
        },
        item: function(data, escape) {
          if(data.display){
            return JSON.parse(data.display);
          }
        },
      },
    });
  }


}
