import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tabs"
export default class extends Controller {
  connect() {
    console.log("Connected to Tabs")
  }

  changeTab(event){
    console.log(event.target.dataset.tabselctor)
    const myTab = tailwind.Tab.getOrCreateInstance(document.querySelector(event.target.dataset.tabselctor));
    myTab.show()
  }

}
