import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="commitments"
export default class extends Controller {
  connect() {
    console.log("connected to Commitments")
  }


  changeCommitment(event){
    const frame = document.getElementById('content');
    frame.src = "/view_commitments/?event_id=" + event.target.value;
  }

  toggleNotes(event){
    const notes = document.getElementById('no-interest-notes')
    if (event.target.checked){
      notes.hidden = false
    }else{
      notes.hidden = true
    }
  }

  changeEvent(event){
    console.log(event.target.value)
  }

}
