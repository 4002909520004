import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

// Connects to data-controller="floored-groups"
export default class extends Controller {
  connect() {
    console.log('connected to Floored Groups')
  }

  changeFlooredBaseGroup(event){
    get("floored_base_groups/" + event.target.dataset.flooredBaseGroupId, {responseKind: "turbo-stream", query: {base_group_sku_id: event.target.value}})
  }
}
