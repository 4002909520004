import { Controller } from "@hotwired/stimulus"
import { tns } from "tiny-slider/src/tiny-slider";

// Connects to data-controller="slider"
export default class extends Controller {

  static values = {
    items: Number,
    container: String,
    axis: String
  }

  initialize(){
    var slider = tns({
      container: this.containerValue,
      items: this.itemsValue,
      slideBy: 'page',
      navAsThumbnails: true,
      autoplay: false
    });
  }


  connect() {
  }
}
