import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="nfc-writer"
export default class extends Controller {
  static values = {
    url: String
  }
  static targets = ["icon", "scanning", "button", "console"];


  async scan(event){
    let object = this.consoleTarget
    console.log("User clicked scan button");
    object.innerHTML = "USER CLICKED BUTTON"
    let ndef = window.NDEFReader
    if (ndef){
      this.iconTarget.hidden = !this.iconTarget.hidden
      this.scanningTarget.hidden = !this.scanningTarget.hidden
      this.buttonTarget.classList.toggle("bg-warning")
      this.buttonTarget.classList.toggle("bg-secondary")
      try {
        ndef = new NDEFReader();
        await ndef.write({
          records: [{ recordType: "url", data: this.urlValue }],
        });
        object.innerHTML = "Message written..." + this.urlValue
        this.element.remove()
      } catch (error) {
        object.innerHTML = "Argh! " + error
      }
    }else{
      object.innerHTML = "NFC NOT WORKING"
    }
  }

  connect() {
    if(window.NDEFReader){
      let object = this.consoleTarget
      if(object){
        object.innerHTML = "Connected!!!!!"
      }
    }else{
      this.element.remove()
    }
  }

}
