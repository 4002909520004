import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="covers"
export default class extends Controller {

  static targets = ["color", "cover"]

  connect() {
    console.log('connected to cover search')
  }

  showHideDocuments(searchValue){
    this.colorTargets.forEach((color) => {
      this.search(color, searchValue)
    })
    this.coverTargets.forEach((cover) => {
      if (cover.getElementsByClassName('color').length <= 0){
        cover.hidden = true
      }else{
        cover.hidden = false
      }
    })
  }

  searchField(event){
    this.showHideDocuments(event.target.value.toLowerCase())
  }

  search(color, searchValue) {
    if (color.textContent.toLowerCase().includes(searchValue)) {
      color.hidden = false
      color.classList.add("color");
    }else{
      color.hidden = true
      color.classList.remove("color");
    }
  }

}
