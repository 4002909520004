import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {

  connect() {
  }
  show(event) {
    const modal = tailwind.Modal.getOrCreateInstance(document.querySelector("#modal"))
    modal.show();
  }
}
