import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="documents"
export default class extends Controller {

  static targets = ["file", "folder"]

  connect() {
    console.log('connected to documents')
  }

  changeFolder(event) {
    const searchValue = document.querySelector('#document-search').value.toLowerCase()
    this.showHideDocuments(event.target.dataset['folder'], searchValue)
  }

  showHideDocuments(folderName, searchValue){
    this.fileTargets.forEach((file) => {
      if (folderName === "ALL"){
        this.search(file, searchValue)
      } else {
        if (file.dataset['folder'] === folderName) {
          this.search(file, searchValue)
        } else {
          file.hidden = true
        }
      }
    })
  }

  searchField(event){
    const folderName = document.querySelector('.nav-link.active').dataset['folder']
    this.showHideDocuments(folderName, event.target.value.toLowerCase())
  }

  search(file, searchValue) {
    if (file.textContent.toLowerCase().includes(searchValue)) {
      file.hidden = false
    }else{
      file.hidden = true
    }
  }

}
