import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="special-pricing"
export default class extends Controller {

  static targets = ["price", "proposedPrice", "approvedPrice", "totalPrice", "totalProposalPrice", "totalApprovedPrice", "difference"]

  connect() {
    console.log('connected to Special Pricing')
    this.getOriginalTotalPrice()
    this.getProposalTotalPrice()
    this.getApprovedTotalPrice()
  }

  updateProposedTotal(event){
    this.changeDisableds(event)
    this.getProposalTotalPrice()
  }

  updateApprovedTotal(event){
    this.changeDisableds(event)
    this.getApprovedTotalPrice()
  }

  changeDisableds(event){
    let price = event.target.value
    document.querySelectorAll('[id=' + event.target.id +']').forEach(element => {
      if(element.disabled == true){
        element.value = price
      }
    })
  }

  calculateDifference(format){
    if (this.hasTotalPriceTarget){
      let originalTotal = parseFloat(this.totalPriceTarget.dataset['value'])
      let num = 0.0
      if (format){
        if (this.hasTotalProposalPriceTarget){
          let proposedTotal = parseFloat(this.totalProposalPriceTarget.dataset['value'])
          num = ((1 - (proposedTotal / originalTotal)) * -100).toFixed(2)
        }
      }else{
        if (this.hasTotalApprovedPriceTarget){
          let approvedTotal = parseFloat(this.totalApprovedPriceTarget.dataset['value'])
          num = ((1 - (approvedTotal / originalTotal)) * -100).toFixed(2)
        }
      }
      this.differenceTarget.innerHTML = num + "%"
      if (num >= 0){
        this.differenceTarget.classList.add('text-green-500')
        this.differenceTarget.classList.remove('text-red-500')
      }else{
        this.differenceTarget.classList.add('text-red-500')
        this.differenceTarget.classList.remove('text-green-500')
      }
    }
  }

  getOriginalTotalPrice(){
    let total = 0.0
    this.priceTargets.forEach(element => {
      total += parseFloat(element.dataset['value'])
    });
    if(this.hasTotalPriceTarget){
      this.totalPriceTarget.dataset['value'] = total
      this.totalPriceTarget.innerHTML = "$"+String(total)
    }
  }

  getProposalTotalPrice(){
    let total = 0.0
    this.proposedPriceTargets.forEach(element => {
      if(element.value){
        total += parseFloat(element.value)
      }
      if(element.dataset['value']){
        total += parseFloat(element.dataset['value'])
      }
    });
    if(this.hasTotalProposalPriceTarget){
      this.totalProposalPriceTarget.dataset['value'] = total
      this.totalProposalPriceTarget.innerHTML = "$"+String(total)
      this.calculateDifference(true)
    }
  }

  getApprovedTotalPrice(){
    let total = 0.0
    this.approvedPriceTargets.forEach(element => {
      if(element.value){
        total += parseFloat(element.value)
      }
      if(element.dataset['value']){
        total += parseFloat(element.dataset['value'])
      }
    });
    if(this.hasTotalApprovedPriceTarget){
      this.totalApprovedPriceTarget.dataset['value'] = total
      this.totalApprovedPriceTarget.innerHTML = "$"+String(total)
      this.calculateDifference(false)
    }
  }

}
