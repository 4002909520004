import { Controller } from "@hotwired/stimulus"

export default class extends Controller{
  static targets = ['link']
  static classes = ['active']

  initialize(){
    this.handleTurboClick = this.handleTurboClick.bind(this)
  }

  connect(){
    this.element.addEventListener('turbo:click', this.handleTurboClick)
  }

  disconnect(){
    this.element.removeEventListener('turbo:click', this.handleTurboClick)
  }

  handleTurboClick(event){
    this.linkTargets.forEach(target => {
      if(target == event.target){
        target.classList.add(...this._activeClasses)
      }else{
        target.classList.remove(...this._activeClasses)
      }
    })
  }

  get _activeClasses(){
    return this.activeClasses.length == 0 ? ['side-menu--active'] : this.activeClasses
  }
}

