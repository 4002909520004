import TomSelect from "tom-select";
import $ from "jquery"

export default function triggerTomSelect(element) {
    (function () {
        "use strict";

        // Tom Select
        $(element).find("select.tom-select").each(function () {
            if ($(this).hasClass('tomselected')) {

            } else {


            let options = {
                maxOptions: null,
                plugins: {
                    dropdown_input: {},
                },
            };

            if ($(this).data("placeholder")) {
                options.placeholder = $(this).data("placeholder");
            }

            if ($(this).attr("multiple") !== undefined) {
                options = {
                    ...options,
                    plugins: {
                        ...options.plugins,
                        remove_button: {
                            title: "Remove this item",
                        },
                    },
                    persist: false,
                    create: false,
                    onDelete: function () {
                    },
                };
            }

            // if ($(this).data("header")) {
            //     options = {
            //         ...options,
            //         plugins: {
            //             ...options.plugins,
            //             dropdown_header: {
            //                 title: $(this).data("header"),
            //             },
            //         },
            //     };
            // }

            new TomSelect(this, options);
        }
    });
    })();
}
