import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="slide-over"
export default class extends Controller {
  static targets = ["hide", "show"]
  slideOverContent = document.querySelector("#slide-over-modal-dialog")
  mySlideOver = tailwind.Modal.getOrCreateInstance(document.querySelector("#slide-over"));
  modal = tailwind.Modal.getOrCreateInstance(document.querySelector("#modal"));

  connect() {
  }

  hideTargetConnected(element){
    this.mySlideOver.hide();
    element.remove();
  }

  showTargetConnected(element){
    this.mySlideOver.show();
    element.remove();
  }

  scrollTo(event){
    console.log("clicked Slide To")
    this.mySlideOver.hide();
    const element = document.getElementById(event.params.id);
    element.scrollIntoView({ behavior: "instant", block: "center", inline: "center" });
  }

  show(event){
    this.xlCheck(event.params)
    this.mySlideOver.show();
  }

  hide(){
    this.mySlideOver.hide();
  }

  modalShow(event) {
    this.modal.show();
  }

  xlCheck(params){
    this.slideOverContent.classList.remove("modal-xl");
    this.slideOverContent.classList.remove("modal-lg");
    if (params['size'] == 'xl') {
      this.slideOverContent.classList.add("modal-xl");
    } else if (params['size'] == 'lg') {
      this.slideOverContent.classList.add("modal-lg");
    }
  }

}
