import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'
import TomSelect from "tom-select";

// Connects to data-controller="orders"
export default class extends Controller {
  static targets = ["account", "name", "city", "state", "zipCode", "country", "address", "accountId", "inventoryItemSelect"]

  ordersTable = document.querySelector("#order-table")
  addInventoryItem = document.querySelector("#add-inventory-item")

  connect() {
    console.log("connected to orders")
  }

  inventoryItemSelectTargetConnected(element){
    new TomSelect(element,{
      valueField: 'id',
      labelField: 'name',
      optgroupField: 'class',
      maxOptions: null,
      searchField: ['name','display','id'],
      sortField:[{field:'name'}],
      render: {
        option: function(data, escape) {
          if(data.display){
            return JSON.parse(data.display);
          }
        },
        item: function(data, escape) {
          if(data.display){
            return JSON.parse(data.display);
          }
        },
      },
    });
  }

  async select(event){
    event.preventDefault()
    console.log(event.target.value)
    const request = new FetchRequest("get", "/contacts/" + event.target.value + "/get_contact", {responseKind: "json"})
    const response = await request.perform()
    if (response.ok){
      const attr = await response.json
      console.log(attr)
      this.accountTarget.value = attr['account']
      this.accountIdTarget.value = attr['account_id']
      this.nameTarget.value = attr['name']
      this.addressTarget.value = attr['address']
      this.cityTarget.value = attr['city']
      this.stateTarget.value = attr['state']
      this.zipCodeTarget.value = attr['zipCode']
      this.countryTarget.value = attr['country']
    }

  }

  matchingRow(id){
    return this.findMatchingRow(id)
  }

  assignMatchingRow(id){
    return this.ordersTable.querySelector(`[data-value='${id}'].item`)
  }

  findMatchingRow(id){
    let found = this.assignMatchingRow(id)
    if(!found){
      this.addInventoryItem.click()
      this.makeVisible(this.ordersTable.rows[this.ordersTable.rows.length - 1])
      return this.ordersTable.rows[this.ordersTable.rows.length - 1]
    }else{
      this.makeVisible(found.closest('tr'))
      return found.closest('tr')
    }
  }

  makeVisible(row){
    row.querySelector('input.destroy').value = false
    row.style = null
  }

  setValues(id, row, quantity){
    row.querySelector(`[data-id='inventory_item']`).value = id
    row.querySelector(".inventory_item_quantity").value = (Number(row.querySelector(".inventory_item_quantity").value) + quantity)
  }

  addItem(event){
    let matchingRow = this.matchingRow(event.target.dataset.id)
    this.setValues(event.target.dataset.id, matchingRow, 1)
  }

  addAllItems(event){
    const array = JSON.parse(event.target.dataset.ids)
    array.forEach(element => {
      let matchingRow = this.matchingRow(element)
      this.setValues(element, matchingRow, 1);
    });
  }

}
